<template>
  <div class="content-box">
    <div v-if="hasPrivilege" class="content-header">
      <div class="totalView box-container column-direction">
        <div class="card-box">
          <div class="card-one">
            <div class="operation-area">
              <span class="operation-icon-box">
                <el-image style="width: 20px; height: 20px" :src="sjhz" />
                数据概况
              </span>
              <div>
                <div class="ysb-checkbox">
                  <el-checkbox
                    v-model="sjhzChecked"
                    @change="sjhzCheckedChange"
                  >
                    <div>养身帮连锁门店</div>
                  </el-checkbox>
                  <el-popover
                    placement="bottom-end"
                    width="280"
                    trigger="click"
                    content="指自营店、联营店、加盟店，不含合作店"
                  >
                    <img
                      class="issue-icon"
                      slot="reference"
                      src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                    />
                  </el-popover>
                </div>

                <el-button
                  @click="viewAllReport()"
                  v-bind:class="overviewDataType == 0 ? 'active' : ''"
                  >全部</el-button
                >
                <el-button
                  @click="viewTodayReport('overviewDataType')"
                  v-bind:class="overviewDataType == 1 ? 'active' : ''"
                  >今日</el-button
                >
                <el-button
                  @click="viewYesterdayReport('overviewDataType')"
                  v-bind:class="overviewDataType == 2 ? 'active' : ''"
                  >昨日</el-button
                >
                <el-date-picker
                  :value="selectDate"
                  :picker-options="pickerOptions"
                  @input="changeValue($event, 'selectDate', 'overviewDataType')"
                  format="yyyy-MM-dd"
                  type="daterange"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  class="date-range-box"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </div>
            </div>
            <div class="box">
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">用户总数(个)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="用户总数=客户总数+健康档案用户数"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      userData.allUserCount != undefined
                        ? userData.allUserCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">客户总数(个)</span>
                    <span class="item-val">{{
                      userData.allCustomerCount != undefined
                        ? userData.allCustomerCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">健康档案用户数(个)</span>
                    <span class="item-val">{{
                      userData.healthRecordsCount != undefined
                        ? userData.healthRecordsCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">新增客户(个)</span>
                    <span class="item-val">{{
                      userData.newUserCount != undefined
                        ? userData.newUserCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">门店总数(个)</span>
                    <span class="item-val">{{
                      shopData.allStoreCount != undefined
                        ? shopData.allStoreCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">调理次数(次)</span>
                    <span class="item-val">{{
                      shopData.healthCount != undefined
                        ? shopData.healthCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">检测次数(次)</span>
                    <span class="item-val">{{
                      shopData.detectionCount != undefined
                        ? shopData.detectionCount
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">检测剩余(次)</span>
                    <span class="item-val">{{
                      dataGeneral.totalLeft ?? "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">检测分配(次)</span>
                    <span class="item-val">{{
                      dataGeneral.totalDispatch ?? "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-one">
            <div>
              <div class="operation-area">
                <span class="operation-icon-box">
                  <el-image style="width: 20px; height: 20px" :src="yssj" />
                  营收数据
                </span>
                <div>
                  <div class="ysb-checkbox">
                    <el-checkbox
                      v-model="yssjChecked"
                      @change="yssjCheckedChange"
                    >
                      养身帮连锁门店
                    </el-checkbox>

                    <el-popover
                      placement="bottom-end"
                      width="280"
                      trigger="click"
                      content="指自营店、联营店、加盟店，不含合作店"
                    >
                      <img
                        class="issue-icon"
                        slot="reference"
                        src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                      />
                    </el-popover>
                  </div>

                  <el-button
                    @click="viewTodayReport('overviewRevenueDataType')"
                    v-bind:class="overviewRevenueDataType == 1 ? 'active' : ''"
                    >今日</el-button
                  >
                  <el-button
                    @click="viewYesterdayReport('overviewRevenueDataType')"
                    v-bind:class="overviewRevenueDataType == 2 ? 'active' : ''"
                    >昨日</el-button
                  >
                  <el-date-picker
                    :value="revenueSelectDate"
                    :picker-options="pickerOptions"
                    @input="
                      changeValue(
                        $event,
                        'revenueSelectDate',
                        'overviewRevenueDataType'
                      )
                    "
                    format="yyyy-MM-dd"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    class="date-range-box"
                    :clearable="false"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  ></el-date-picker>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      总现金(元)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="总现金=门店总现金类收款（门店所有现金类支付方式的收款总额，未减去退卡、退套餐支付出去的现金金额)"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.totalCash != undefined
                        ? turnoverData.totalCash
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      总营业额(元)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="总营业额=服务业绩+商品业绩(服务业绩: 服务项目收到的现金+卡耗总额；商品业绩：售出商品产生的现金+卡耗总额)"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.totalMoney != undefined
                        ? turnoverData.totalMoney
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      开卡总额(元)
                      <el-popover
                        placement="bottom-end"
                        width="220"
                        trigger="click"
                        content="开卡总额=充值总额+开卡工本费"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.totalCard != undefined
                        ? turnoverData.totalCard
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      服务业绩(元)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="服务业绩=服务实际入账，这里均不含会员卡充值和套餐销售的收入(如存在商品和服务混合订单，则按售价比例进行拆分实际入账金额)"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.serviceActualIncome != undefined
                        ? turnoverData.serviceActualIncome
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      商品业绩(元)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="商品业绩=商品实际入账，这里均不含会员卡充值和套餐销售的收入(如存在商品和服务混合订单，则按售价比例进行拆分实际入账金额)"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.goodsActualIncome != undefined
                        ? turnoverData.goodsActualIncome
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="box-16">
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">
                      套餐业绩(元)
                      <el-popover
                        placement="bottom-end"
                        width="280"
                        trigger="click"
                        content="指在疗程/套餐和自定义套餐内全部支付方式购买的服务和商品的实际收入总和"
                      >
                        <img
                          class="issue-icon"
                          slot="reference"
                          src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                        />
                      </el-popover>
                    </span>
                    <span class="item-val">{{
                      turnoverData.course != undefined
                        ? turnoverData.course
                        : "待更新"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-box">
          <div class="card-one">
            <div class="operation-area">
              <span class="operation-icon-box">
                <el-image style="width: 20px; height: 20px" :src="jrys" />
                今日营收
              </span>
              <div class="ysb-checkbox">
                <el-checkbox v-model="jrysChecked" @change="jrysCheckedChange">
                  <div>养身帮连锁门店</div>
                </el-checkbox>
                <el-popover
                  placement="bottom-end"
                  width="280"
                  trigger="click"
                  content="指自营店、联营店、加盟店，不含合作店"
                >
                  <img
                    class="issue-icon"
                    slot="reference"
                    src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                  />
                </el-popover>
              </div>
            </div>
            <div class="box">
              <div
                v-for="(item, index) in todayRevenue"
                :key="index"
                class="box-16"
              >
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">{{ item.title }}</span>
                    <span class="item-val">{{ item.val ?? "待更新" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-one">
            <div class="operation-area">
              <span class="operation-icon-box">
                <el-image style="width: 20px; height: 20px" :src="sb" />
                设备统计
              </span>
              <div class="ysb-checkbox">
                <el-checkbox v-model="bcChecked" @change="bcCheckedChange">
                  <div>去养博城商城数据</div>
                </el-checkbox>
              </div>
              <div class="ysb-checkbox">
                <el-checkbox v-model="sbChecked" @change="sbCheckedChange">
                  <div>养身帮连锁门店</div>
                </el-checkbox>
                <el-popover
                  placement="bottom-end"
                  width="280"
                  trigger="click"
                  content="指自营店、联营店、加盟店，不含合作店"
                >
                  <img
                    class="issue-icon"
                    slot="reference"
                    src="https://yuyi-1301954635.cos.ap-guangzhou.myqcloud.com/icon/ic_demand_issue.png"
                  />
                </el-popover>
              </div>
            </div>
            <div class="box">
              <div
                v-for="(item, index) in deviceStatistics"
                :key="index"
                class="box-16"
              >
                <div class="box-16-item">
                  <div class="item-row">
                    <span class="item-title">{{ item.title }}</span>
                    <span class="item-val">{{ item.val ?? "待更新" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WeekDay />
      <div class="detectView box-container">
        <div class="box-33">
          <label class="label-title"
            >今日检测（{{ this.totalDetectionCount }}次）</label
          >
          <div class="chart-container" ref="detectchart"></div>
        </div>
        <div class="box-33">
          <label class="label-title"
            >今日调理（{{ this.totalConditionCount }}次）</label
          >
          <div class="chart-container" ref="conditionchart"></div>
        </div>
        <div class="box-33">
          <label class="label-title">实时数据（近1小时）</label>
          <div class="chart-container" ref="realtimechart"></div>
        </div>
      </div>
      <div class="shopView box-container">
        <div class="box-33">
          <label class="label-title">门店检测数TOP 10</label>
          <div class="chart-container" ref="topdetectchart"></div>
        </div>
        <div class="box-33">
          <label class="label-title">门店调理数TOP 10</label>
          <div class="chart-container" ref="topconditionchart"></div>
        </div>
        <div class="box-33">
          <label class="label-title">门店营收数据详情</label>
          <div class="chart-container" ref="topturnoverchart"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// TODO: 剩余选择时间重新调用数据
import { Mixins, Component } from "vue-property-decorator";
import loading from "@/mixins/loading";
import dayjs from "dayjs";

import { toYuanFixed } from "@/utils/common";

import watermark from "@/utils/watermark";

const echarts = require("echarts");
// README: 这个页面如果再加入功能，全部需要组件化
// FIXME: 后续将echarts 的options 全部迁出到config 中间中

import {
  getAllUserData,
  getAllStoreData,
  getAllDetectDigData,
  getAllTotalData,
  getDeviceAndBizData,
  getBusinessData,
} from "@/api/home";
import { getUserList } from "@/api/userMember";
import { getAllDiagnosesData } from "@/api/tongue";

import WeekDay from "./components/weekDay.vue";

import sjhz from "@/assets/icon/sjhz.png";
import jrys from "@/assets/icon/jrys.png";
import sb from "@/assets/icon/sb.png";
import yssj from "@/assets/icon/yssj.png";

@Component({
  components: { WeekDay },
})
export default class Home extends Mixins(loading) {
  sjhz = sjhz;
  jrys = jrys;
  sb = sb;
  yssj = yssj;

  hasPrivilege = true;
  // 当前数据总览的时间类型（0全部,1今日,2昨日,3自定义）
  overviewDataType = 1;
  overviewRevenueDataType = 1;
  revenueSelectDate = [];
  selectDate = [];
  pickerOptions = {
    disabledDate: (time) => {
      return time.getTime() > Date.now(); // 禁止选择今天以后的日期
    },
  };
  // 获取用户相关数据
  userData = {
    allUserCount: 0, // 用户总数
    allCrallCustomerCount: 0, // 客户总数
    newUserCount: 0, // 新增用户
    healthRecordsCount: 0, // 健康档案总数
  };
  // 获取门店相关数据
  shopData = {
    allStoreCount: 0, // 门店总数
    newStoreCount: 0, // 新增门店
  };
  // 获取检测和调理记录数据
  detectAndConditionData = {
    tongueUserNumber: 0, // 今日舌诊记录
    pulseTakingUserCount: 0, // 今日脉诊记录
    physiqueTestNumber: 0, // 今日体质辨证记录
    healthDetailCountList: [], // 今日调理：艾灸、熏蒸、温通、筋膜、超短波
    realTimeDetectionCount: 0, // 实时检测人数
    realTimeHealthCount: 0, // 实时调理人数
  };
  // 今日检测总次数
  totalDetectionCount = 0;
  // 今日调理总测试
  totalConditionCount = 0;
  // 获取营业数据汇总
  turnoverData = {
    totalMoney: 0, // 总营业额
    totalCard: 0, // 开卡总金额(分)
    totalCash: 0, // 总现金
    goodsActualIncome: 0, // 商品业绩
    serviceActualIncome: 0, // 服务业绩
    storeTotalMoneyDataList: [], // 门店营业额前十
    course: 0, // 套餐业绩
  };
  // 设备和调理数据
  deviceAndBiz = {
    aiDeviceCount: 0,
    otherDeviceCount: 0,
    otherDetectionCount: 0,
    aiJiuCount: 0,
    xunZhengCount: 0,
    nengLiangshouCount: 0,
    chaoDuanboCount: 0,
    detectionCount: 0,
    wenTongyiCount: 0,
    boxCount: 0,
    healthCount: 0,
    gzcCount: 0,
  };

  revenueDataBiz = {
    aliPay: 0,
    card: 0,
    cardGift: 0,
    cash: 0,
    course: 0,
    free: 0,
    others: 0,
    unionPay: 0,
    wechatPay: 0,
  };

  // 数据概况概况 检测剩余 检测分配
  dataGeneral = {
    totalLeft: 0, // 检测
    totalDispatch: 0, // 分配
  };

  detectChart = null;
  conditionChart = null;
  realtimeChart = null;
  topDetectChart = null;
  topConditionChart = null;
  topTurnoverChart = null;

  sjhzChecked = false;
  yssjChecked = false;
  jrysChecked = false;
  sbChecked = false;
  bcChecked = false;

  // revenueDataBiz
  get todayRevenue() {
    return [
      {
        icon: "ic_demand_amount",
        title: "现金(元)",
        val: toYuanFixed(this.revenueDataBiz.cash),
      },
      {
        icon: "ic_demand_amount",
        title: "银联(元)",
        val: toYuanFixed(this.revenueDataBiz.unionPay),
      },
      {
        icon: "ic_demand_amount",
        title: "微信(元)",
        val: toYuanFixed(this.revenueDataBiz.wechatPay),
      },
      {
        icon: "ic_demand_amount",
        title: "支付宝(元)",
        val: toYuanFixed(this.revenueDataBiz.aliPay),
      },
      {
        icon: "ic_demand_amount",
        title: "耗卡(元)",
        val: toYuanFixed(this.revenueDataBiz.card),
      },
      {
        icon: "ic_demand_amount",
        title: "疗程消耗(元)",
        val: toYuanFixed(this.revenueDataBiz.course),
      },
      {
        icon: "ic_demand_amount",
        title: "耗赠金(元)",
        val: toYuanFixed(this.revenueDataBiz.cardGift),
      },
      {
        icon: "ic_demand_amount",
        title: "免单(元)",
        val: toYuanFixed(this.revenueDataBiz.free),
      },
      {
        icon: "ic_demand_amount",
        title: "其他(元)",
        val: toYuanFixed(this.revenueDataBiz.others),
      },
    ];
  }

  get deviceStatistics() {
    return [
      {
        icon: "ic_demand_amount",
        title: "中医大脑（总）",
        val: this.deviceAndBiz.aiDeviceCount,
      },
      {
        icon: "ic_demand_amount",
        title: "调理设备（总）",
        val: this.deviceAndBiz.otherDeviceCount,
      },
      {
        icon: "ic_demand_amount",
        title: "检测设备（总）",
        val: this.deviceAndBiz.otherDetectionCount,
      },
      {
        icon: "ic_demand_amount",
        title: "艾灸大师",
        val: this.deviceAndBiz.aiJiuCount,
      },
      {
        icon: "ic_demand_amount",
        title: "智能药熏舱",
        val: this.deviceAndBiz.xunZhengCount,
      },
      {
        icon: "ic_demand_amount",
        title: "筋膜能量手",
        val: this.deviceAndBiz.nengLiangshouCount,
      },
      {
        icon: "ic_demand_amount",
        title: "超短波调理仪",
        val: this.deviceAndBiz.chaoDuanboCount,
      },
      {
        icon: "ic_demand_amount",
        title: "智能温通仪",
        val: this.deviceAndBiz.wenTongyiCount,
      },
      {
        icon: "ic_demand_amount",
        title: "通讯盒子",
        val: this.deviceAndBiz.boxCount,
      },
      {
        icon: "ic_demand_amount",
        title: "智能生物共振",
        val: this.deviceAndBiz.gzcCount,
      },
    ];
  }

  mounted() {
    let menus = this.$store.getters.userinfo.menus;
    this.hasPrivilege = menus.some(obj => obj["id"] == 47);
    if (!this.hasPrivilege) {
      return;
    }

    const detectChartRef = this.$refs.detectchart;
    const conditionChartRef = this.$refs.conditionchart;
    const realtimeChartRef = this.$refs.realtimechart;
    const topDetectChartRef = this.$refs.topdetectchart;
    const topConditionChartRef = this.$refs.topconditionchart;
    const topTurnoverChartRef = this.$refs.topturnoverchart;

    this.detectChart = echarts.init(detectChartRef);
    this.conditionChart = echarts.init(conditionChartRef);
    this.realtimeChart = echarts.init(realtimeChartRef);
    this.topDetectChart = echarts.init(topDetectChartRef);
    this.topConditionChart = echarts.init(topConditionChartRef);
    this.topTurnoverChart = echarts.init(topTurnoverChartRef);

    this.updateCharts();
    this.getData();

    const username = localStorage.getItem("yysj_username");
    const markText = `${username} 禁止转发截图`;
    watermark.set(markText, document.querySelector(".content-box"));
  }

  destroyed() {
    watermark.hide();
  }

  sjhzCheckedChange($event) {
    this.getUserDataCount();
  }
  yssjCheckedChange($event) {
    this.getAllTotalData();
  }
  jrysCheckedChange($event) {
    this.getBusinessData();
  }
  sbCheckedChange() {
    this.getDeviceAndBizData();
  }
  bcCheckedChange() {
    this.getDeviceAndBizData();
    this.getUserDataCount();
  }

  // 改变顶部搜索输入框的值，通过type区分不同的输入框
  changeValue(val, type, key) {
    if (type == "selectDate" || type == "revenueSelectDate") {
      const maxDays = 180; // 设置最大天数
      const startDate = val[0];
      const endDate = val[1];

      // 计算选择的日期范围
      const diffDays = dayjs(endDate).diff(dayjs(startDate), "day");
      if (diffDays > maxDays) {
        this.$message.error("时间段最大不能超过180天");
        return;
      }

      this[type] = val;
      this[key] = 3;
      if (type == "selectDate") {
        this.getUserDataCount();
      }
      if (type == "revenueSelectDate") {
        this.getAllTotalData();
      }
      // this.getData();
    }
  }

  viewAllReport() {
    this.overviewDataType = 0;
    this.selectDate = [];
    this.getUserDataCount();
  }

  viewTodayReport(key) {
    this[key] = 1;
    if (key == "overviewDataType") {
      this.selectDate = [];
      this.getUserDataCount();
    } else {
      this.revenueSelectDate = [];
      this.getAllTotalData();
    }
  }

  viewYesterdayReport(key) {
    this[key] = 2;
    if (key == "overviewDataType") {
      this.selectDate = [];
      this.getUserDataCount();
    } else {
      this.revenueSelectDate = [];
      this.getAllTotalData();
    }
  }

  // 数据概况
  getUserDataCount() {
    // 请求参数
    let beginTime = null;
    let endTime = null;
    if (this.overviewDataType == 1) {
      beginTime = dayjs().format("YYYY-MM-DD 00:00:00");
      endTime = dayjs().format("YYYY-MM-DD 23:59:59");
    } else if (this.overviewDataType == 2) {
      const yesterdayStr = dayjs().subtract(1, "day").format("YYYY-MM-DD");
      beginTime = yesterdayStr + " 00:00:00"; // 昨日开始时间
      endTime = yesterdayStr + " 23:59:59"; // 昨日结束时间
    } else if (this.overviewDataType == 3) {
      beginTime =
        this.selectDate &&
        this.selectDate.toString().split(",")[0] + " 00:00:00";
      endTime =
        this.selectDate &&
        this.selectDate.toString().split(",")[1] + " 23:59:59";
    }
    const param =
      beginTime && endTime
        ? {
            beginTime: beginTime,
            endTime: endTime,
          }
        : {};

    param.isAllStore = Number(!this.sjhzChecked);
    param.ignoreYbcData = Number(this.bcChecked);

    getAllUserData(param)
      .then((res) => {
        this.hideLoading();
        this.userData = res.data;
        this.updateCharts();
      })
      .catch((err) => {
        this.hideLoading();
      });

    getAllStoreData(param)
      .then((res) => {
        this.hideLoading();
        this.shopData = res.data;
        this.updateCharts();
      })
      .catch((err) => {
        this.hideLoading();
      });

    this.getAllDiagnoses();
  }

  // 营收数据
  getAllTotalData() {
    // 请求参数
    let beginTime = null;
    let endTime = null;
    if (this.overviewRevenueDataType == 1) {
      beginTime = dayjs().format("YYYY-MM-DD");
      endTime = dayjs().format("YYYY-MM-DD");
    } else if (this.overviewRevenueDataType == 2) {
      const yesterdayStr = dayjs().subtract(1, "day").format("YYYY-MM-DD");
      beginTime = yesterdayStr 
      // + " 00:00:00"; // 昨日开始时间
      endTime = yesterdayStr 
      // + " 23:59:59"; // 昨日结束时间
    } else if (this.overviewRevenueDataType == 3) {
      beginTime =
        this.revenueSelectDate &&
        this.revenueSelectDate.toString().split(",")[0]
        //  + " 00:00:00";
      endTime =
        this.revenueSelectDate &&
        this.revenueSelectDate.toString().split(",")[1]
        //  + " 23:59:59";
    }
    const param =
      beginTime && endTime
        ? {
            beginTime: beginTime,
            endTime: endTime,
          }
        : {};

    param.isAllStore = Number(!this.yssjChecked);
    getAllTotalData(param)
      .then((res) => {
        this.hideLoading();
        this.turnoverData = res.data;
        this.turnoverData.totalMoney = (
          this.turnoverData.totalMoney / 100
        ).toFixed(2);
        this.turnoverData.totalCard = (
          this.turnoverData.totalCard / 100
        ).toFixed(2);
        this.turnoverData.totalCash = (
          this.turnoverData.totalCash / 100
        ).toFixed(2);
        this.turnoverData.serviceActualIncome = (
          this.turnoverData.serviceActualIncome / 100
        ).toFixed(2);
        this.turnoverData.goodsActualIncome = (
          this.turnoverData.goodsActualIncome / 100
        ).toFixed(2);
        this.turnoverData.course = (this.turnoverData.course / 100).toFixed(2);
        this.updateCharts();
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  // 营收数据
  getBusinessData() {
    const params = { isAllStore: Number(!this.jrysChecked) };
    getBusinessData(params).then((res) => {
      Object.assign(this.revenueDataBiz, res.data);
    });
  }

  getData() {
    this.getAllTotalData();
    this.getUserDataCount();
    this.getBusinessData();
    this.getDeviceAndBizData();
    getAllDetectDigData({})
      .then((res) => {
        this.hideLoading();
        this.detectAndConditionData = res.data;
        if (res.data) {
          this.totalDetectionCount =
            res.data.totalDetectionCount;
          this.totalConditionCount = 0;
          if (res.data.healthDetailCountList) {
            res.data.healthDetailCountList.forEach((item) => {
              this.totalConditionCount += item.value;
            });
          }
        }
        this.updateCharts();
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  getDeviceAndBizData() {
    const params = { isAllStore: Number(!this.sbChecked), ignoreYbcData: Number(this.bcChecked)};
    getDeviceAndBizData(params)
      .then((res) => {
        this.hideLoading();
        this.deviceAndBiz = res.data;
        this.updateCharts();
      })
      .catch((err) => {
        this.hideLoading();
      });
  }

  // 检测剩余(次)  检测分配(次)
  getAllDiagnoses() {
    const params = {
      isAllStore: Number(!this.sjhzChecked),
    };
    getAllDiagnosesData(params)
      .then((res) => {
        const { totalLeft, totalDispatch } = res.data;
        Object.assign(this.dataGeneral, { totalLeft, totalDispatch });
      })
      .catch((err) => {});
  }

  updateCharts() {
    console.log("detectAndConditionData", this.detectAndConditionData)
    // 今日检测饼图数据
    const detectOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}次",
      },
      series: [
        {
          type: "pie",
          label: {
            show: true,
            formatter: "{b}\n{c}次",
          },
          data: [
            {
              value: this.detectAndConditionData.tongueUserNumber,
              name: "舌诊",
            },
            {
              value: this.detectAndConditionData.pulseTakingUserCount,
              name: "脉诊",
            },
            {
              value: this.detectAndConditionData.physiqueTestNumber,
              name: "体质辨识",
            },
            {
              value: this.detectAndConditionData.pefNumber,
              name: "肺功能检测",
            },
            {
              value: this.detectAndConditionData.weigherNumber,
              name: "智能体脂",
            },
          ],
        },
      ],
    };

    // 今日调理饼图数据
    const conditionOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}次",
      },
      series: [
        {
          type: "pie",
          label: {
            show: true,
            formatter: "{b}\n{c}次",
          },
          data: this.detectAndConditionData.healthDetailCountList,
        },
      ],
    };

    // 实时检测调理饼图数据
    const realtimeOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}次",
      },
      series: [
        {
          type: "pie",
          label: {
            show: true,
            formatter: "{b}\n{c}次",
          },
          data: [
            {
              value: this.detectAndConditionData.realTimeDetectionCount,
              name: "检测",
            },
            {
              value: this.detectAndConditionData.realTimeHealthCount,
              name: "调理",
            },
          ],
        },
      ],
    };

    // 今日门店检测数TOP 10
    const topTenDetectionData = this.detectAndConditionData.topTenDetection;
    var topTenDectNames = ["今日暂无数据"];
    var topTenDectValues = [0];
    if (topTenDetectionData && topTenDetectionData.length) {
      topTenDetectionData.sort((a, b) => a.value - b.value);
      topTenDectNames = topTenDetectionData.map((obj) => obj.name);
      topTenDectValues = topTenDetectionData.map((obj) => obj.value);
    }
    var topDetectOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}次",
      },
      legend: {
        show: false,
      },
      grid: {
        top: 20,
        left: "3%",
        right: "15%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        data: topTenDectNames,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "bar",
          data: topTenDectValues,
          barWidth: 25,
          label: {
            show: true,
            position: "right",
          },
        },
      ],
    };

    // 今日门店调理数TOP 10
    const topTenConditionData = this.detectAndConditionData.topTenHealth;
    var topTenHealthNames = ["今日暂无数据"];
    var topTenHealthValues = [0];
    if (topTenConditionData && topTenConditionData.length) {
      topTenConditionData.sort((a, b) => a.value - b.value);
      topTenHealthNames = topTenConditionData.map((obj) => obj.name);
      topTenHealthValues = topTenConditionData.map((obj) => obj.value);
    }
    var topConditionOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}次",
      },
      color: ["#3CB371", "#00CA99", "#9C67E0", "#F7C739", "#4D8EFF"],
      legend: {
        show: false,
      },
      grid: {
        top: 20,
        left: "3%",
        right: "15%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        data: topTenHealthNames,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "bar",
          data: topTenHealthValues,
          barWidth: 25,
          label: {
            show: true,
            position: "right",
          },
        },
      ],
    };

    // 今日门店总营业额
    const topTenTurnoverData = this.turnoverData.storeTotalMoneyDataList;
    var topTenTurnoverNames = ["今日暂无数据"];
    var topTenTurnoverValues = [0];
    if (topTenTurnoverData && topTenTurnoverData.length) {
      topTenTurnoverData.sort((a, b) => a.todayMoney - b.todayMoney);
      topTenTurnoverNames = topTenTurnoverData.map((obj) => obj.storeName);
      topTenTurnoverValues = topTenTurnoverData.map((obj) =>
        (obj.todayMoney / 100).toFixed(2)
      );
    }
    var topTurnoverOptions = {
      tooltip: {
        trigger: "item", // 鼠标悬停在数据项上触发 tooltip
        formatter: "{b}：{c}元",
      },
      color: ["#F95757", "#00CA99", "#9C67E0", "#F7C739", "#4D8EFF"],
      legend: {
        show: false,
      },
      grid: {
        top: 20,
        left: "3%",
        right: "15%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
      yAxis: {
        type: "category",
        data: topTenTurnoverNames,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "bar",
          data: topTenTurnoverValues,
          barWidth: 25,
          label: {
            show: true,
            position: "right",
          },
        },
      ],
    };

    // 设置数值展示图标
    this.detectChart.setOption(detectOptions);
    this.conditionChart.setOption(conditionOptions);
    this.realtimeChart.setOption(realtimeOptions);
    this.topDetectChart.setOption(topDetectOptions);
    this.topConditionChart.setOption(topConditionOptions);
    this.topTurnoverChart.setOption(topTurnoverOptions);
  }
}
</script>

<style lang="scss" scoped src="./style.scss"></style>
<style lang="scss" scoped>
.card-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  margin: 5px 0;
  border-radius: 25px 25px 25px 25px;
  background: #fff;
  box-sizing: border-box;
  .card-one {
    width: calc(50% - 10px);
    .box {
      display: flex;
      flex-wrap: wrap;
    }

    &:nth-of-type(1n) {
      .box {
        border-right: 1px solid rgba(241, 237, 237, 0.77);
      }
    }
    &:nth-of-type(2n) {
      .box {
        border-right: none;
      }
    }
  }
}
.ysb-checkbox {
  display: inline-flex;
  align-items: center;
  .issue-icon {
    width: 12px;
    height: 12px;
    margin: 5px 0 0 5px;
    margin-right: 12px;
  }
}
.operation-icon-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  & + div{
    text-align: end;
  }
  .el-image {
    margin-right: 4px;
  }
}
</style>
